@import '/src/global/media.scss';

.about_container {
  background-image: url("../../../../public/images/bg-header.svg");
  background-position-x: center;
  padding: 28px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin-bottom: 100px;
  background-repeat: repeat-x;
  @include media-mobile {
    padding: 0 20px;
    gap: 30px;
    background-position-x: 3%;
    background-size: cover;
  }
}
.about_description {
  width: 691px;
  height: 60px;
  font-style: normal;
  font-weight: 400;
  font-size: var(--aboutDescriptionSize);
  line-height: 30px;
  text-align: center;
  color: var(--color-dark);
}

.about_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  max-width: 905px;
  font-size: var(--headerSize);
  line-height: 88px;
  text-align: center;
  color: var(--color-dark);
}

.about_content_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about_block {
  background: #005ef5;
  border-radius: 12px;
  max-width: 1312px;
  color: var(--light-color);
  padding: 60px 40px 80px;
  margin: 0px 64px;
  margin-top: -294px;
  overflow: hidden;
  @include media-mobile {
    padding: 25px;
  }
}

.about_block_title {
  position: relative;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  height: 100px;
  margin-bottom: 46px;
  display: flex;
  align-items: center;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -40px;
    transform: translateY(-50%);
    width: 436px;
    height: 100px;
    background-image: url("data:image/svg+xml,%3Csvg width='436' height='100' viewBox='0 0 436 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M387 1.01011C393.095 1.13342 399.117 2.38311 404.754 4.69783C410.7 7.13906 416.102 10.717 420.651 15.2269C425.201 19.7369 428.81 25.0904 431.271 30.9818C433.606 36.5695 434.864 42.2622 434.99 48.263L387 48.263V1.01011ZM336.251 1.19761C342.406 1.32206 348.487 2.59457 354.18 4.95277C360.182 7.4386 365.635 11.0821 370.228 15.6754C374.821 20.2686 378.465 25.7215 380.95 31.7228C383.312 37.4244 384.581 43.0162 384.705 49.1095H336.251V1.19761ZM380.95 81.0405C383.309 86.7337 384.581 92.8147 384.706 98.9698L336.251 98.9698V51.1151C342.466 51.1849 348.532 51.9308 354.18 54.2705C360.182 56.7563 365.635 60.3998 370.228 64.9931C374.821 69.5863 378.465 75.0392 380.95 81.0405ZM435 96.9899C422.364 96.7346 410.301 91.6476 401.349 82.7731C392.386 73.889 387.27 62.7197 387.01 50.263L435 50.263V96.9899ZM1 1H55C82.062 1 104 22.938 104 50C104 77.062 82.062 99 55 99H1V1ZM156 1H285C312.062 1 334 22.938 334 50C334 77.062 312.062 99 285 99H156C128.938 99 107 77.062 107 50C107 22.938 128.938 1 156 1Z' stroke='white' stroke-opacity='0.2' stroke-width='2'/%3E%3C/svg%3E%0A");
    background-size: contain;
    background-repeat: no-repeat;
    pointer-events: none;
    @include media-mobile {
      left: -27px;
      width: 311px;
      height: 72px;
    }
  }
}

@include media-mobile {
  .about_block_title {
    margin-bottom: 40px;
  }
}

.about_mapping_content {
  display: flex;
  flex-direction: row;
  gap: 113.5px;
}

.about_item_content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.about_item_content_title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: #ffffff;
}
.about_item_content_description {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

@include media-tablet {
  .about_container {
    margin-bottom: 110px;
  }
  .about_block_title {
    font-size: 32px;
    line-height: 39px;
  }
  .about_title {
    max-width: 360px;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 1.5;

    text-align: center;

    color: #000000;
  }
  .about_description {
    width: 320px;
    height: 96px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
  .about_position_block {
    top: -19px;
    position: relative;
    height: 0px;
  }
  .about_mapping_content {
    flex-direction: column;
    gap: 32px;
  }
  .about_block {
    margin: 0px 8px;
    margin-top: -30px;
  }
}
