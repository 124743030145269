@import '/src/global/media.scss';

.integrations {
  position: relative;
  overflow: hidden;
  &__bg {
    position: absolute;
    right: -24px;
    bottom: 0;
    z-index: 0;
    max-width: 790px;
    @include media-mobile {
      right: unset;
      left: 20px;
      width: 667px;
    }
  }
  &__inner.inner {
    position: relative;
    padding-bottom: 120px;
    @include media-mobile {
      padding-bottom: 96px;
    }
    > :not(.integrations__bg) {
      position: relative;
      z-index: 1;
    }
  }
  &__ui-h2 {
    max-width: 990px;
    margin-bottom: 124px;
    @include media-mobile {
      margin-bottom: 90px;
    }
  }
  &__slider {
    overflow: unset;
    .swiper-wrapper {
      box-sizing: border-box;
    }
  }
  &__item {
    width: 140px;
    height: auto;
    background: #fff;
    border: 1px solid #DDDFE0;
    border-radius: 16px;
    overflow: hidden;
    &--text {
      display: flex;
      width: auto;
      max-width: 328px;
      .integrations__name {
        padding: 40px;
        margin: auto;
      }
    }
  }
  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 118px;
    border-bottom: 1px solid #DDDFE0;
  }
  &__name {
    padding: 12px 5px 16px;
    font-size: 16px;
    line-height: 1.2;
    color: #676767;
    text-align: center;
  }
}
