* {
  box-sizing: inherit;
  font-family: "Inter";
}
*:focus {
  outline: none;
}
html {
  scrollbar-gutter: stable;
}
button {
  outline: none;
  border-style: none;
}
input {
  outline: none;
  border-style: none;
}
body {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0;
  padding: 0;
  color: var(--color-dark);
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
pre,
span,
ul,
ol,
li,
tr,
td,
th,
table,
blockquote {
  margin: 0;
  padding: 0;
}
img,
svg {
  max-width: 100%;
  max-height: 100%;
  display: block;
}
input,
button,
textarea,
select {
  font: inherit;
}
input[type="text"] {
  appearance: none;
}
a {
  text-decoration: none;
  color: inherit;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    color: var(--primary-color);
  }
}
.grecaptcha-badge {
  visibility: hidden;
  opacity: 0;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
