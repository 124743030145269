@import '/src/global/media.scss';

.privacy_container {
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 100%;
  max-width: 705px;
  margin: 0 auto;
}

.privacy_title {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 68px;
  color: #000000;
}
.privacy_content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  ul {
    padding-left: 20px;
  }
}
.privacy_content_title {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  color: #000000;
  margin-bottom: 24px;
}

.privacy_content a {
  text-decoration: none;
  color: #005ef5;
}

@include media-mobile {
  .privacy_content {
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }
  .privacy_title {
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
  }
  .privacy_content_title {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
  }
  .privacy_content ul {
    padding-left: 24px;
  }
}
