@mixin media-laptop {
  @media (max-width: 1362px) {
    @content;
  }
}
@mixin media-tablet {
  @media (max-width: 1024px) {
    @content;
  }
}
@mixin media-mobile {
  @media (max-width: 767px) {
    @content;
  }
}
