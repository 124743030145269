@import '/src/global/media.scss';

.footer {
  padding: 0 40px 32px;
  @include media-tablet {
    padding: 0 0 24px;
  }
  &__inner.inner {
    max-width: none;
  }
  &__top {
    display: grid;
    grid-template-columns: 1fr 0.8fr 0.8fr;
    gap: 50px;
    justify-content: space-between;
    @include media-tablet {
      grid-template-columns: 1fr 0.5fr 0.8fr;
      gap: 32px;
    }
    @include media-mobile {
      grid-template-columns: 1fr;
    }
  }
  &__about {
    max-width: 360px;
  }
  &__logo {
    display: block;
    width: 100px;
    height: 27px;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
  &__section {
    max-width: 235px;
    @include media-mobile {
      max-width: none;
    }
    &-name {
      &:not(:last-child) {
        margin-bottom: 16px;
        font-size: 16px;
        text-transform: uppercase;
        color: #888;
      }
    }
  }
  &__menu {
    list-style: none;
  }
  &__item {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
  &__link {
    font-size: 16px;
    line-height: 1.5;
    color: inherit;
    text-decoration: none;
    &:hover {
      color: var(--primary-color);
    }
  }
  &__text {
    font-size: 16px;
    line-height: 1.5;
    color: #888;
  }
  &__contact {
    &-link {
      display: flex;
      gap: 16px;
      color: var(--dark-color);
      font-weight: 400;
      font-size: 16px;
      text-decoration: none;
      transition: 0.3s;
      &:hover {
        color: var(--primary-color);
      }
      &:not(:first-child) {
        margin-top: 12px;
      }
      svg {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
      }
    }
    &-descr {
      &:not(:first-child) {
        margin-top: 4px;
        font-weight: 400;
        font-size: 12px;
        color: #888;
      }
    }
  }
  &__socials {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    margin-top: 16px;
  }
  &__social {
    width: 32px;
    height: 32px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  &__bottom {
    margin-top: 40px;
    border-top: 1px solid #D9D9D9;
    padding-top: 32px;
    text-align: center;
    font-size: 16px;
    line-height: 1.5;
    color: #888;
    @include media-mobile {
      margin-top: 32px;
      padding-top: 24px;
    }
  }
}

.footer__container {
  padding: 0px 64px;
}

.footer__content {
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  justify-content: space-between;
  padding-bottom: 32px;
  margin-bottom: 32px;
}

.footer__description_phone {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  width: 231px;
  color: var(--gray-color);
}

.footer__description {
  font-style: normal;
  font-weight: 400;
  font-size: var(--descriptionContentSize);
  line-height: 16px;
  width: 361px;
  color: var(--gray-color);
}

.footer__dors {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.footer__connect {
  padding-top: 39px;
  display: flex;
  gap: 38px;
}

.footer__madeYear {
  display: flex;
  justify-content: center;
  padding-bottom: 34px;
  font-style: normal;
  font-weight: 400;
  font-size: var(--descriptionContentSize);
  line-height: 24px;
  color: var(--gray-color);
}

.footer__contacts {
  padding-bottom: 4px;
  display: flex;
  align-items: center;
  gap: 18px;
  color: black;
  text-decoration: none;
}
.footer__social {
  display: flex;
  gap: 35.5px;
}
@media screen and (max-width: 1060px) {
  .footer__content {
    flex-direction: column;
  }
  .footer__connect {
    flex-direction: column;
  }

  .footer__social {
    display: flex;
    justify-content: space-between;
  }
  .footer__description {
    width: 100%;
  }
  .footer__container {
    padding: 0px 20px;
  }
}
