@import '/src/global/media.scss';

.ui-h2 {
  font-weight: 600;
  font-size: 40px;
  line-height: 1.5;
  @include media-tablet {
    font-size: 34px;
  }
  @include media-mobile {
    font-size: 24px;
  }
}
