@import '/src/global/media.scss';

.details_container {
  .inner {
    display: grid;
    gap: 48px;
    @include media-mobile {
      gap: 32px;
    }
  }
}

.details_title {
  font-style: normal;
  font-weight: 600;
  font-size: var(--subHeaderSize);
  line-height: 60px;
  width: 796px;
  color: var(--dark-color);
}

.details_render_item {
  background: var(--gray-primary-color);
  border-radius: 12px;
  min-height: 196px;
  padding: 32px;
  display: flex;
  gap: 14px;
  flex-direction: column;
}

.details_render_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  @include media-tablet {
    grid-template-columns: 1fr;
  }
}

.details_render_item_header {
  font-style: normal;
  font-weight: 500;
  font-size: var(--descriptionTitleSize);
  line-height: 36px;
  color: var(--dark-color);
  display: flex;
  align-items: center;
  gap: 14px;
}

.details_render_item_content {
  font-style: normal;
  font-weight: 400;
  font-size: var(--descriptionContentSize);
  line-height: 24px;
  /* identical to box height, or 150% */

  color: var(--dark-color);
}
@include media-tablet {
  .details_render_item {
    height: auto;
  }
}
@include media-laptop {
  .details_title {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    color: #000000;
    width: 700px;
  }
}
@include media-tablet {
  .details_title {
    width: 320px;
  }
}
