@import '/src/global/media.scss';

.ui-button {
  display: inline-block;
  padding: 16px 24px;
  background: var(--primary-color);
  color: #fff;
  border-radius: 4px;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2;
  white-space: nowrap;
  cursor: pointer;
  @include media-tablet {
    padding: 8px 16px;
    font-size: 16px;
    line-height: 1.5;
  }
  &[disabled] {
    pointer-events: none;
    opacity: 0.6;
  }
  &--xs {
    padding: 8px 16px;
    font-size: 16px;
    line-height: 1.5;
  }
  &--white {
    background: #fff;
    color: #000;
  }
  &--m-w100 {
    @include media-mobile {
      width: 100%;
    }
  }
}
