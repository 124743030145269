:root {
  /*Цвета*/
  --dark-color: #000000;
  --primary-color: #005ef5;
  --light-color: #ffffff;
  --gray-color: #888888;
  --gray-primary-color: #f6f6f6;

  /*Размеры шрифтов*/
  --headerSize: 64px;
  --subHeaderSize: 40px;
  --descriptionContentSize: 16px;
  --descriptionTitleSize: 24px;
  --aboutDescriptionSize: 20px;
}
