@import '/src/global/media.scss';

.partner_title {
  font-style: normal;
  font-weight: 600;
  font-size: var(--subHeaderSize);
  line-height: 60px;
  color: var(--dark-color);
  padding-bottom: 24px;
}

.partner_description {
  font-style: normal;
  font-weight: 400;
  font-size: var(--descriptionContentSize);
  line-height: 24px;
  width: 669px;
  color: var(--dark-color);
  margin-bottom: 32px;
}
.partner_render_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  @include media-tablet {
    grid-template-columns: 1fr;
  }
}
.partner_render_item {
  background: #26272d;
  border-radius: 12px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.partner_render_item_svg {
  display: flex;
  justify-content: center;
}

.partner_render_item_title {
  font-style: normal;
  font-weight: 500;
  font-size: var(--descriptionTitleSize);
  line-height: 29px;
  /* identical to box height */

  color: var(--light-color);
}
.partner_render_item_content {
  font-style: normal;
  font-weight: 400;
  font-size: var(--descriptionContentSize);
  line-height: 24px;
  color: var(--light-color);
}
@include media-tablet {
  .partner_description {
    width: 520px;
  }
  .partner_render_container {
    flex-direction: column;
  }
}
@include media-mobile {
  .partner_description {
    width: 320px;
  }
  .partner_title {
    font-size: 32px;
    line-height: 44px;
  }
}
