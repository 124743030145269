@import '/src/global/media.scss';

.risks_container {
  &--svg {
    .risks__ui-h2 {
      @include media-mobile {
        max-width: 320px;
      }
    }
    .risks_render_container {
      justify-content: flex-start;
      gap: 133px;
      @include media-laptop {
        gap: 80px;
      }
      @include media-tablet {
        padding: 24px 0;
        gap: 0;
      }
      &_item {
        @include media-tablet {
          gap: 8px;
        }
      }
      &_title {
        width: 405px;
        @include media-laptop {
          width: 300px;
        }
      }
      &_content {
        width: auto;
        max-width: 600px;
      }
    }
  }
}
.risks_head {
  margin-bottom: 40px;
}
.risks__ui-h2 {
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}
.risks_title {
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: var(--subHeaderSize);
  line-height: 60px;
  color: var(--color-dark);
}
.risks_description {
  font-style: normal;
  font-weight: 400;
  font-size: var(--descriptionContentSize);
  line-height: 24px;
  width: 490px;
  color: var(--color-dark);
}

.risks_render_container {
  display: flex;
  justify-content: space-between;
  padding-top: 32px;
  padding-bottom: 32px;
}

.risks_render_container_item {
  display: flex;
  gap: 48px;
}

.risks_render_container_svg {
  width: 64px;
  height: 64px;
  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.risks_render_container_index {
  font-style: normal;
  font-weight: 400;
  font-size: var(--descriptionTitleSize);
  line-height: 29px;
  color: var(--primary-color);
}

.risks_render_container_title {
  font-style: normal;
  font-weight: 500;
  font-size: var(--descriptionTitleSize);
  width: 295px;
  line-height: 29px;
  color: var(--color-dark);
  @include media-mobile {
    width: auto;
  }
}

.risks_render_container_content {
  width: 724px;
  font-style: normal;
  font-weight: 400;
  font-size: var(--descriptionContentSize);
  line-height: 24px;
  color: var(--color-dark);
}

@include media-tablet {
  .risks_head {
    margin-bottom: 0;
  }
  .risks_description {
    width: 500px;
  }
  .risks_render_container_item {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .risks_render_container {
    flex-direction: column;
  }
  .risks_render_container_content {
    width: 100%;
    padding-top: 16px;
  }
}
@include media-tablet {
  .risks_description {
    width: 320px;
  }
}
