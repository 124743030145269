@import '/src/global/media.scss';

.popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 500;
  display: flex;
  background: rgba(0, 0, 0, 0.4);
  animation: fade-in 0.5s;
  overflow-y: auto;
  overflow-x: hidden;
  @include media-tablet {
    height: 100vh;
    overflow: unset;
    @supports (height: 100dvh) {
      height: 100dvh;
    }
  }
  &__content {
    --pd: 32px;

    position: relative;
    width: 100%;
    max-width: 425px;
    margin: auto;
    padding: var(--pd);
    background: #fff;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.16);
    border-radius: 12px;
    @include media-tablet {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      height: 100%;
      border-radius: 0;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(100%, -100%);
    display: flex;
    width: 32px;
    height: 32px;
    padding: 0;
    color: #fff;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    border: 0;
    cursor: pointer;
    @include media-tablet {
      top: 34px;
      right: 20px;
      width: 24px;
      height: 24px;
      transform: none;
      background: none;
      border-radius: 0;
      color: inherit;
    }
    svg {
      width: 18px;
      height: 18px;
      margin: auto;
      @include media-tablet {
        width: 100%;
        height: 100%;
      }
    }
  }
  &__head {
    margin-bottom: 32px;
    margin-left: -12px;
  }
  &__logo {
    display: inline-block;
    width: 100px;
    height: 27px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
