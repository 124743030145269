@import '/src/global/media.scss';

.possibilities {
  &:not(:first-child) {
    margin-top: 105px;
    @include media-tablet {
      margin-top: 80px;
    }
    @include media-mobile {
      margin-top: 40px;
    }
  }
  &__ui-h2 {
    max-width: 570px;
    margin-bottom: 35px;
    @include media-mobile {
      margin-bottom: 20px;
    }
  }
  &__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    @include media-tablet {
      grid-template-columns: 1fr;
      gap: 12px;
    }
  }
  &__item {
    padding: 16px 16px 20px;
    border: 1px solid #DDDFE0;
    border-radius: 12px;
    @include media-mobile {
      padding: 8px 12px 12px;
    }
  }
  &__svg {
    width: 64px;
    height: 64px;
    margin-bottom: 16px;
    @include media-tablet {
      margin-bottom: 8px;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
  &__name {
    max-width: 560px;
    font-size: 20px;
    line-height: 1.5;
    @include media-tablet {
      font-size: 16px;
    }
  }
  &__foot {
    text-align: center;
    margin-top: 45px;
    @include media-tablet {
      margin-top: 20px;
    }
  }
  &__ui-button {
    @include media-mobile {
      width: 100%;
    }
  }
}
