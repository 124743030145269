@import '/src/global/media.scss';

.header {
  position: relative;
  z-index: 30;
  padding: 0 40px;
  @include media-tablet {
    padding: 32px 0;
  }
  &__inner.inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
    max-width: none;
  }
  &__logo {
    width: 100px;
    height: 27px;
    flex-shrink: 0;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  &__burger {
    display: flex;
    align-items: inherit;
    gap: inherit;
    @include media-tablet {
      position: absolute;
      top: calc(100%);
      left: 0;
      width: 100%;
      height: calc(100vh - 100%);
      @supports (height: 100dvh) {
        height: calc(100dvh - 100%)
      }
      flex-direction: column;
      align-items: stretch;
      padding: 22px 20px 45px;
      background: #fff;
      transition: 0.3s;
      opacity: 0;
      visibility: hidden;
      overflow-y: auto;
      overflow-x: hidden;
    }
    &.active {
      opacity: 1;
      visibility: visible;
    }
    &-button {
      display: grid;
      grid-template-areas: 'svg';
      padding: 0;
      background: none;
      cursor: pointer;
    }
    &-icon {
      width: 24px;
      height: 24px;
      grid-area: svg;
      transition: 0.3s;
      opacity: 0;
      svg {
        width: 100%;
        height: 100%;
      }
      &.active {
        opacity: 1;
      }
    }
  }
  &__menu {
    display: flex;
    align-items: inherit;
    gap: inherit;
    list-style: none;
    @include media-tablet {
      flex-direction: column;
      align-items: stretch;
      gap: 15px;
      margin: auto -20px;
    }
  }
  &__item {
    position: relative;
    padding: 34px 0;
    @include media-tablet {
      padding: 0 20px;
    }
  }
  &__link {
    font-weight: 500;
    font-size: 16px;
    color: inherit;
    text-decoration: none;
    @include media-tablet {
      font-weight: 400;
      font-size: 32px;
      line-height: 1.4;
      color: #888;
    }
    &.active {
      pointer-events: none;
      @include media-tablet {
        color: var(--dark-color);
      }
      &::before {
        transition: 0.7s;
        opacity: 1;
      }
    }
    &:hover {
      color: var(--primary-color);
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -8px;
      right: -8px;
      height: 4px;
      background: var(--primary-color);
      border-radius: 0px 0px 2px 2px;
      opacity: 0;
      transition: 0.3s;
      @include media-tablet {
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 12px;
        height: 13px;
        background-color: transparent;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='14' fill='none'%3E%3Cpath stroke='%23005EF5' stroke-width='1.4' d='M.7 1.2h4.8a5.8 5.8 0 0 1 0 11.6H.7V1.2Z'/%3E%3C/svg%3E");
        background-size: contain;
        background-repeat: no-repeat;
        border-radius: 0;
      }
    }
  }
  &__contact {
    position: relative;
    @include media-tablet {
      margin-top: auto;
    }
    &-link {
      display: flex;
      gap: 16px;
      color: var(--dark-color);
      font-weight: 400;
      font-size: 16px;
      text-decoration: none;
      transition: 0.3s;
      &:hover {
        color: var(--primary-color);
      }
      &:not(:first-child) {
        margin-top: 12px;
      }
      svg {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
      }
    }
    &-descr {
      &:not(:first-child) {
        margin-top: 4px;
        font-weight: 400;
        font-size: 12px;
        color: #888;
      }
    }
  }
  &__popup {
    position: absolute;
    top: calc(100% + 12px);
    right: 0;
    width: 280px;
    padding: 24px;
    background: #fff;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.16);
    border-radius: 12px;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    @include media-tablet {
      position: relative;
      top: unset;
      right: unset;
      width: unset;
      padding: 0;
      box-shadow: unset;
      visibility: unset;
      opacity: 1;
    }
    &.active {
      opacity: 1;
      visibility: visible;
    }
    .header__ui-button {
      width: 100%;
    }
  }
  &__socials {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    margin: 32px 0;
    @include media-tablet {
      margin: 24px 0 0;
    }
  }
  &__social {
    width: 32px;
    height: 32px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
