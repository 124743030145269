@import '/src/global/media.scss';

.data-analytics {
  &__top {
    margin-bottom: 32px;
    @include media-tablet {
      margin-bottom: 20px;
    }
  }
  &__ui-h2 {
    &:not(:last-child) {
      margin-bottom: 40px;
      @include media-tablet {
        margin-bottom: 20px;
      }
    }
  }
  &__descr {
    max-width: 545px;
    font-size: 16px;
    line-height: 1.5;
  }
  &__section {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 200px;
      background: #f3f3f3;
      @include media-mobile {
        position: sticky;
        height: 132px;
      }
    }
    &::after {
      position: absolute;
      left: 0;
      bottom: -12px;
      z-index: 10;
      width: 100%;
      height: 12px;
      background: #fff;
      @include media-mobile {
        content: '';
      }
    }
    @include media-mobile {
      display: flex;
      flex-direction: column;
    }
    &:not(:last-child) {
      margin-bottom: 64px;
    }
  }
  &__head {
    position: relative;
    padding: 32px 0;
    background: #f3f3f3;
    @include media-mobile {
      position: sticky;
      top: 0;
      z-index: 10;
      padding: 20px 0;
      margin-top: -132px;
    }
    &::before,
    &::after {
      position: absolute;
      bottom: -12px;
      width: 12px;
      height: 12px;
      background-size: contain;
      background-repeat: no-repeat;
      @include media-mobile {
        content: '';
      }
    }
    &::before {
      content: '';
      left: 20px;
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 0H0V12C0 5.37256 5.37256 0 12 0Z' fill='%23F3F3F3'/%3E%3C/svg%3E%0A");
    }
    &::after {
      right: 20px;
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 0H12V12C12 5.37258 6.62742 0 0 0Z' fill='%23F3F3F3'/%3E%3C/svg%3E%0A");
    }
    .inner {
      display: flex;
      gap: 16px;
      align-items: baseline;
      @include media-mobile {
        gap: 8px;
      }
    }
  }
  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 1.5;
    @include media-mobile {
      font-size: 16px;
    }
  }
  &__num {
    width: 48px;
    border-radius: 50%;
    color: #fff;
    background: var(--primary-color);
    font-size: 20px;
    line-height: 48px;
    text-align: center;
    flex-shrink: 0;
    @include media-mobile {
      width: 32px;
      font-size: 14px;
      line-height: 32px;
    }
  }
  &__body {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 24px;
    @include media-tablet {
      grid-template-columns: minmax(0, 1fr);
    }
  }
  &__item {
    --pd-x: 24px;
    --pd-y: 22px;

    padding: var(--pd-y) var(--pd-x);
    background: #fff;
    border: 1px solid #dcdee0;
    border-radius: 12px;
    @include media-mobile {
      --pd-x: 20px;

      display: flex;
      flex-direction: column;
    }
    &::before,
    &::after {
      position: sticky;
      top: var(--head-height, 91px);
      z-index: 5;
      width: calc(50% + var(--pd-x));
      height: 12px;
      background-size: contain;
      background-repeat: no-repeat;
      border: 1px solid #dcdee0;
      border-bottom-width: 0;
      order: -2;
      @include media-mobile {
        content: '';
      }
    }
    &::before {
      margin-top: calc((var(--pd-y) * -1) - 1px);
      margin-left: calc((var(--pd-x) * -1) - 1px);
      border-radius: 12px 0 0 0;
      border-right-width: 0;
    }
    &::after {
      margin-top: -13px;
      margin-right: calc((var(--pd-x) * -1) - 1px);
      margin-left: auto;
      margin-bottom: var(--pd-y);
      border-radius: 0 12px 0 0;
      border-left-width: 0;
    }
  }
  &__charts {
    display: flex;
    gap: 45px;
    @include media-mobile {
      flex-direction: column;
      gap: 24px;
    }
    .data-analytics__name {
      margin-bottom: 24px;
      @include media-mobile {
        margin-bottom: 12px;
      }
    }
  }
  &__chart {
    display: flex;
    flex-direction: column;
  }
  &__name {
    max-width: 265px;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.5;
    @include media-mobile {
      max-width: 240px;
      font-size: 16px;
    }
  }
  &__pie-chart {
    margin-top: auto;
    width: 225px;
    height: 225px !important;
    @include media-mobile {
      margin: 0 auto;
    }
    > div {
      overflow: unset !important;
    }
    svg {
      overflow: visible;
    }
  }
  &__bar-chart {
    margin-top: 40px;
    @include media-mobile {
      margin: 10px calc(var(--pd-x) * -1) 0;
      padding: 0 var(--pd-x);
      overflow-x: auto;
    }
    &::-webkit-scrollbar {
      height: 3px;
    }
    &::-webkit-scrollbar-track {
      margin: 0 20px;
    }
    &::-webkit-scrollbar-thumb {
      background: #DDDFE0;
      border-radius: 2px;
    }
    .echarts-for-react {
      height: 240px !important;
      @include media-mobile {
        min-width: 580px;
      }
    }
  }
  &__legend {
    max-width: 400px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px 24px;
    margin-top: 24px;
    font-size: 16px;
    line-height: 1.5;
    @include media-mobile {
      order: -1;
      margin-top: 0;
      margin-bottom: 24px;
      gap: 12px;
      font-size: 14px;
    }
    &--line {
      display: grid;
      gap: 8px;
      .data-analytics__legend {
        &-item {
          gap: 20px;
        }
        &-icon {
          width: 22px;
          height: 3px;
          border-radius: 0;
        }
      }
    }
    &--mt16 {
      margin-top: 16px;
      @include media-mobile {
        margin-top: 0;
      }
    }
    &-icon {
      width: 20px;
      height: 20px;
      border-radius: 8px;
      flex-shrink: 0;
      background: var(--color);
      @include media-mobile {
        width: 16px;
        height: 16px;
        border-radius: 4px;
      }
    }
    &-item {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
  &__tooltip {
    z-index: 4 !important;
    .percent {
      font-weight: 700;
      font-size: 20px;
      color: #000;
    }
  }
}
.latest-publications {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 48px;
  margin: 34px calc(var(--pd-x) * -1) 0;
  padding: 20px var(--pd-x) 0;
  border-top: 1px solid #DDDFE0;
  @include media-mobile {
    grid-template-columns: 1fr;
    gap: 24px;
    margin: 16px calc(var(--pd-x) * -1) 0;
    padding: 16px var(--pd-x) 0;
  }
  &__name {
    margin-bottom: 18px;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.5;
    @include media-mobile {
      margin-bottom: 13px;
      font-size: 16px;
    }
  }
  &__list {
    display: grid;
    gap: 12px;
    @include media-mobile {
      gap: 8px;
    }
  }
  &__item {
    display: grid;
    gap: 10px;
    justify-content: space-between;
    grid-template-columns: 53% 1fr;
    font-size: 16px;
    line-height: 1.5;
    @include media-mobile {
      font-size: 14px;
    }
  }
  &__date {
    display: grid;
    grid-template-columns: 20px 1fr;
    align-items: center;
    gap: 8px;
    color: #787878;
    &::before {
      content: '';
      width: 20px;
      height: 20px;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='10' cy='10' r='8.2' stroke='%23787878' stroke-width='1.6'/%3E%3Cpath d='M9.72156 7V8.82791C9.72156 9.47381 10.0335 10.08 10.5591 10.4554L12.7216 12' stroke='%23787878' stroke-width='1.6' stroke-linecap='round'/%3E%3C/svg%3E%0A");
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}

@keyframes barHeightAnim {
  0% {
    min-height: 0%;
  }
  100% {
    min-height: var(--height);
  }
}
@keyframes barWidthtAnim {
  0% {
    width: 0%;
  }
  100% {
    width: var(--height);
  }
}
