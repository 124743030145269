@import '/src/global/media.scss';

.banner {
  background: #F3F3F3;
  overflow: hidden;
  &__inner {
    display: flex;
    position: relative;
    min-height: 425px;
    @include media-mobile {
      min-height: unset;
      flex-direction: column;
    }
  }
  &__img {
    position: absolute;
    top: 0;
    right: -40px;
    z-index: 0;
    height: 100%;
    max-width: 737px;
    @include media-tablet {
      right: 0;
      max-width: min(737px, 51.181vw);
    }
    @include media-mobile {
      position: static;
      max-width: unset;
      margin: 0 -20px;
    }
    img {
      height: 100%;
      object-fit: cover;
      object-position: left;
      @include media-mobile {
        height: auto;
        margin: 0 auto;
        object-position: unset;
      }
    }
  }
  &__content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 70px 0 85px;
    @include media-mobile {
      padding: 40px 0 0;
    }
  }
  &__title {
    font-weight: 600;
    font-size: 48px;
    line-height: 1.4;
    @media screen and (max-width: 1430px) {
      font-size: 38px;
    }
    @include media-mobile {
      font-size: 32px;
    }
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
  &__descr {
    max-width: min(625px, 43.403vw);
    font-size: 20px;
    line-height: 1.5;
    @include media-tablet {
      font-size: 18px;
    }
    @include media-mobile {
      max-width: unset;
      font-size: 16px;
    }
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
  &__ui-button {
    margin-top: auto;
  }
}
