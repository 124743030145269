@import '/src/global/media.scss';

.whyAreWe_container {
  padding: 0px 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.whyAreWe_title {
  font-style: normal;
  font-weight: 600;
  font-size: var(--subHeaderSize);
  line-height: 48px;
  color: var(--color-dark);
  margin-bottom: 48px;
}

.whyAreWe_container_ask {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  background-image: url("./bg-svg.svg");
  background-position: center;
  height: 330px;
  @include media-mobile {
    height: auto;
  }
}

.whyAreWe_container_ask_content {
  width: 561px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.whyAreWe_container_ask_btns {
  position: relative;
  top: -45px;
  display: flex;
  gap: 18px;
  align-items: center;
  @include media-mobile {
    top: unset;
    margin-top: 5px;
  }
}

.btn_item {
  width: 6px;
  height: 6px;
  background: #888888;
  border-radius: 20px;
}
@include media-tablet {
  .whyAreWe_container_ask_content {
    width: 320px;
    height: 240px;
  }
  .whyAreWe_container {
    padding: 0px 20px 55px;
  }
}
