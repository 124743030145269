@import '/src/global/media.scss';

.main {
  flex: 1 0;
  padding-bottom: 100px;
  @include media-tablet {
    padding-bottom: 65px;
  }
  section {
    &:not(:first-child) {
      margin-top: 128px;
      @include media-tablet {
        margin-top: 80px;
      }
      @include media-mobile {
        margin-top: 40px;
      }
    }
  }
}
