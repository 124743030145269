@import '/src/global/media.scss';

.connection-step {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: var(--primary-color);
  &__pin {
    position: sticky;
    top: 0;
    width: 100%;
    height: 100vh;
    @supports (height: 100lvh) {
      height: 100lvh;
    }
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 64px 0 70px;
    overflow: hidden;
    @include media-mobile {
      padding: 20px 0;
    }
  }
  &__ui-h2 {
    max-width: 595px;
    color: #fff;
  }
  &__timeline {
    position: relative;
    flex-grow: 1;
    display: flex;
    gap: 95px;
    height: 100%;
    width: 100%;
    padding: 0 64px;
    padding-left: calc(50% - 157px);
    margin-top: 40px;
    @include media-mobile {
      margin-top: 10px;
      padding: 0 20px;
      gap: 40px;
    }
  }
  &__item {
    position: relative;
    z-index: 1;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 1fr auto 1fr;
    gap: 24px;
    justify-items: center;
    align-items: start;
    flex-shrink: 0;
    @media (max-height: 900px) {
      gap: 2.6vh;
    }
    @include media-mobile {
      gap: 20px;
    }
    &:nth-child(odd) {
      .connection-step__text {
        grid-row: 3/4;
      }
    }
    &:nth-child(even) {
      .connection-step__text {
        align-self: end;
      }
    }
    &:nth-child(n) {
      @include media-mobile {
        max-width: unset;
        width: calc(100vw - 40px);
      }
    }
    &--w315 {
      max-width: 315px;
    }
    &--w360 {
      max-width: 360px;
    }
    &--w420 {
      max-width: 420px;
    }
    &--w430 {
      max-width: 430px;
    }
  }
  &__num {
    grid-row: 2/3;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    padding: 20px;
    background: #fff;
    font-size: 20px;
    line-height: 1.2;
    text-align: center;
    @include media-mobile {
      font-size: 18px;
    }
  }
  &__text {
    padding: 20px;
    background: #fff;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.24);
    border-radius: 12px;
    font-size: 20px;
    line-height: 1.5;
    @media (max-height: 900px) {
      padding: 2.2vh;
      font-size: 2.2vh;
    }
    @include media-mobile {
      padding: 15px;
      font-size: 17px;
    }
  }
  &__lines {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    display: grid;
    align-items: center;
    grid-template-areas: 'svg';
    svg {
      display: block;
      width: 100%;
      grid-area: svg;
      overflow: visible;
    }
  }
}
