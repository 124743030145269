@import '/src/global/media.scss';

.inner {
  margin: 0 auto;
  width: 100%;
  max-width: 1362px;
  padding: 0 25px;
  @include media-laptop {
    padding: 0 20px;
  }
  &--max-w750 {
    max-width: 800px;
  }
}
