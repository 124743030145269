@import '/src/global/media.scss';

.ui-select {
  .ui-select {
    &__control {
      background: #f6f6f6;
      border: 1px solid #e9e9e9;
      border-radius: 8px;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.5;
      transition: 0.3s;
      &:hover {
        border-color: #e9e9e9;
      }
      &--is-focused {
        box-shadow: none;
      }
      &--menu-is-open {
        box-shadow: 0 0 0 1px var(--primary-color);
        .ui-select__indicator {
          transform: rotate(180deg);
        }
      }
    }
    &__value-container {
      padding: 8px 16px;
    }
    &__placeholder {
      margin: 0;
      color: #888;
    }
    &__single-value {
      margin: 0;
    }
    &__indicator {
      color: #888;
      transition: 0.3s;
      &:hover {
        color: #888;
      }
      &-separator {
        display: none;
      }
    }
    &__menu {
      background: #f6f6f6;
      border: 1px solid #e9e9e9;
      border-radius: 8px;
      box-shadow: none;
      &-list {

      }
    }
    &__option {
      &--is-selected {
        color: var(--primary-color);
        background: transparent;
      }
    }
  }
}
